import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import { filterResourceByProject } from "@/app/helpers";
import VersionSelect from "@/views/common/VersionSelect.vue";
import Checkbtn from "@/views/common/Checkbtn.vue";
import IconDevops from "@/icons/azure-devops.svg";
import IconRM from "@/icons/rm-icon.svg";
import MultiTasksModal from "./MultiTasksModal.vue";


const PAGE_SIZE = 100;


export default {
    name: 'MultiTasks',
    components: {
        VersionSelect,
        Multiselect,
        MultiTasksModal,
        IconDevops,
        Checkbtn,
        IconRM,
    },
    data() {
        return {
            filters: {
                project: null,
                version: null,
                assignedTo: null,
                onlyParents: false,
                onlyChildren: false,
                onlyDevops: false,
                onlyRedmine: false,
            },
            issues: [],
            versions: [],
            selected: [],
            selectedAll: false,
            modalOpen: false,
            total: 0,
            page: 0,
        };
    },
    computed: {
        ...mapGetters({
            resourcesAll: 'Resource/list/resources',
            projects: 'Project/list/projects',
        }),
        canList() {
            return !!this.filters.project;
        },
        resources() {
            if (!this.filters.project) return [];
            return [{ id: -1, fullname: 'Nobody' }].concat(this.resourcesAll.filter((resource) =>
                filterResourceByProject(resource, this.filters.project)
            ));
        },
        devopsMode() {
            if (!this.selected.length) return false;
            return !!this.issues.find(iss => this.selected.includes(iss.id) && iss.devops_url);
        },
        startOffset() {
            return this.page * PAGE_SIZE;
        },
        endOffset() {
            return this.startOffset + this.issues.length;
        },
    },
    methods: {
        refreshVersions() {
            this.$store.dispatch('Issue/list/getVersions', this.filters.project.id).then((versions) => {
                this.versions = [{ id: -1, name: 'Sans version', status: 'closed' }].concat(versions);
            });
        },
        turnPage(offset) {
            this.page += offset;
            this.refresh();
        },
        refresh() {
            this.selected = [];
            this.selectedAll = false;
            if (!this.filters.project) return (this.issues = []);
            const payload: any = {
                projectId: this.filters.project.id,
                offset: this.startOffset,
                limit: PAGE_SIZE,
            };
            if (this.filters.version) payload.versionId = this.filters.version.id;
            if (this.filters.assignedTo) payload.assignedToId = this.filters.assignedTo.id;
            if (this.filters.onlyParents) payload.onlyParents = '';
            if (this.filters.onlyChildren) payload.onlyChildren = '';
            if (this.filters.onlyDevops) payload.onlyDevops = '';
            if (this.filters.onlyRedmine) payload.onlyRedmine = '';
            this.$store.dispatch('Issue/list/getMultiTasks', payload).then((data) => {
                this.issues = data.data;
                this.total = data.total;
            });
        },
        selectAll() {
            this.selected = this.selectedAll ? this.issues.map(iss => iss.id) : [];
        },
        submit() {
            this.modalOpen = false;
            this.selectedAll = false;
            this.selected = [];
            this.refresh();
        },
    },
    watch: {
        'filters.project'() {
            this.filters.version = null;
            this.filters.assignedTo = null;
            if (!this.filters.project) return (this.versions = []);
            this.refreshVersions();
        },
        'filters.onlyParents'() {
            if (this.filters.onlyParents) this.filters.onlyChildren = false;
        },
        'filters.onlyChildren'() {
            if (this.filters.onlyChildren) this.filters.onlyParents = false;
        },
        'filters.onlyDevops'() {
            if (this.filters.onlyDevops) this.filters.onlyRedmine = false;
        },
        'filters.onlyRedmine'() {
            if (this.filters.onlyRedmine) this.filters.onlyDevops = false;
        },
        filters: {
            deep: true,
            handler() {
                this.page = 0;
                this.refresh();
            }
        },
    },
    mounted() {
        this.$store.dispatch('Project/list/getList');
    },
}
