import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import Modal from '@/views/modal/Modal.vue';
import VersionSelect from "@/views/common/VersionSelect.vue";
import ResourceSelect from "@/views/common/ResourceSelect.vue";
import MultiTasksControl from './MultiTasksControl.vue';
import { ISSUE_PRIORITIES, ISSUE_STATUSES_ID_LABEL } from "@/config/redmine-constants";
import { filterResourceByProject } from "@/app/helpers";


const issueRedmineMapping = {
    project: {
        target: 'project_id',
        converter: (project) => {
            return project ? project.id : null;
        },
        required: true,
    },
    version: {
        target: 'fixed_version_id',
        converter: (version) => {
            if (!version) return null;
            return version.id !== -1 ? version.id : null;
        },
        required: true,
    },
    status: {
        target: 'status_id',
        required: true,
    },
    priority: {
        target: 'priority_id',
        required: true,
    },
    assigned_to: {
        target: 'assigned_to_id',
        required: true,
    },
    phase: {
        required: true,
    },
};


function buildIssueFromSelectedBatch(selected, batch) {
    const issue = selected.reduce((agg, field) => {
        const mapping = issueRedmineMapping[field];
        const target = mapping && mapping.target ? mapping.target : field;
        agg[target] = mapping && mapping.converter ? mapping.converter(batch[field]) : batch[field];
        return agg;
    }, {});

    return issue;
}


export default {
    name: 'MultiTasksModal',
    components: {
        Modal,
        Multiselect,
        VersionSelect,
        ResourceSelect,
        MultiTasksControl,
    },
    props: {
        project: {
            required: true,
        },
        issues: {
            required: true,
        },
        devopsMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            selected: [],
            versions: [],
            statuses: ISSUE_STATUSES_ID_LABEL,
            batch: {
                project: null,
                version: null,
                status: null,
                priority: null,
                assigned_to: null,
                phase: null,
                done_ratio: 0,
            },
            progress: this.issues.length,
            errors: {
                project: false,
                version: false,
                status: false,
                priority: false,
                assigned_to: false,
                phase: false,
            },
        };
    },
    computed: {
        ...mapGetters({
            resources: 'Resource/list/resources',
            projects: 'Project/list/projects',
            resourcesAll: 'Resource/list/resources',
            phases: 'Enumeration/customFields/phases',
        }),
        projectFilter() {
            return (this.isSelected('project') && this.batch.project) ? this.batch.project : this.project;
        },
        priorities() {
            return ISSUE_PRIORITIES.map(prio => ({
                id: prio.id,
                name: prio.devops + ' - ' + prio.redmine,
            }));
        },
        resources() {
            return this.resourcesAll.filter((resource) =>
                filterResourceByProject(resource, this.projectFilter)
            );
        },
    },
    methods: {
        close() {
            this.$emit('close');
        },
        isSelected(field) {
            return this.selected.includes(field)
        },
        select(field) {
            if (this.isSelected(field)) return;
            this.selected.push(field);
        },
        refreshVersions() {
            this.$store.dispatch('Issue/list/getVersions', this.projectFilter.id).then((versions) => {
                this.versions = [{ id: -1, name: 'Sans version', status: 'closed' }].concat(versions);
            });
        },
        validate() {
            Object.keys(this.batch).forEach(field => {
                const required = issueRedmineMapping[field] && issueRedmineMapping[field].required;
                const selected = this.selected.includes(field);
                const empty = this.batch[field] === null;
                this.errors[field] = required && selected && empty;
            });
            return !Object.values(this.errors).find(error => error);
        },
        async submit() {
            if (!this.validate()) return;
            if (!this.selected.length) return;
            const issue = buildIssueFromSelectedBatch(this.selected, this.batch);
            this.progress = 0;
            for (const i in this.issues) {
                const payload = {
                    issueId: this.issues[i],
                    issue,
                };
                await this.$store.dispatch('Issue/edit/update', payload);
                this.progress++;
            }
            this.$emit('submit');
        },
    },
    mounted() {
        this.refreshVersions();
        this.$store.dispatch('Enumeration/customFields/getList');
    }
}
