<style lang="scss">
.multitasks_control {
  position: relative;
  .multitasks_overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}
</style>

<template>
  <div
    :class="[
      'form-group',
      'multitasks_control',
      { opacity7: !selected.includes(value) && !devops, opacity5: devops },
    ]"
    :title="
      devops
        ? 'Synchronized with DevOps so not editable for DevOps tasks'
        : null
    "
  >
    <div v-if="devops" class="multitasks_overlay"></div>
    <div class="d-flex">
      <Checkbox
        v-model="selected"
        :value="value"
        class="mb-1 mr-auto"
        :readonly="devops"
        :disabled="devops"
      >
        {{ label }}
        <IconDevops class="text-muted" v-if="devops" />
      </Checkbox>
      <slot name="right" />
    </div>
    <slot />
  </div>
</template>

<script>
import IconDevops from "@/icons/azure-devops.svg";

export default {
  name: "MultiTasksControl",
  components: { IconDevops },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: false,
    },
    values: {
      default: [],
    },
    devops: {
      default: false,
    },
  },
  model: { prop: "values" },
  computed: {
    selected: {
      get() {
        return this.values;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
